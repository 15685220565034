<template>
  <div>
    <a class="btn btn-sm btn-link" style="color: #6d5a3f" @click="() => {  $router.push({
                        name: 'Post',
                        params: { postId: post.id }
                      }); }">{{post.title}}</a>
  </div>
</template>

<script>
// @ is an alias to /src
import { db } from "@/main";

export default {
  name: "ConnectedPost",
  props: {
    postId: String
  },
  components: {},
  data() {
    return {
      post: null
    };
  },
  mounted() {
    this.retrievePost();
  },
  methods: {
    async retrievePost() {
      const _this = this;

      _this.post = db
          .collection("posts")
          .doc(_this.postId)
          .get()
          .then(function (querySnapshot) {
            _this.post = querySnapshot.data();
            console.log("post data:", _this.post);
            _this.isLoading = false;
          });
    }
  }
};
</script>
